<template>
  <v-card :color="active ? 'primary' : ''" class="d-flex" dark>
    <v-tooltip bottom>
      <template v-slot:activator="{ on }">
        <img  v-on="on"
          @click="action"
          style="cursor: pointer; width: 100%;"
          v-lazy="imgObj"
        />
      </template>
      <span>{{season.name}}</span>
    </v-tooltip>
    <div
      class="text-right"
      style="position:absolute; width: 100%;"
    >
      <v-btn text icon>
        <v-icon>check_circle_outline</v-icon>
      </v-btn>
    </div>
    <div
      v-if="active"
      class="headline flex-grow-1 text-center align-self-center"
      style="position:absolute; width: 100%;"
    >
      <v-btn v-if="false" text icon :to="'/tv/'+tv.id+'/'+season.season_number">
        <v-icon size="100">play_circle_outline</v-icon>
      </v-btn>
      <v-btn block class="mt-5" :to="'/tv/'+tv.id+'/'+season.season_number">{{season.name}}</v-btn>
    </div>
  </v-card>
</template>

<script>
export default {
  props: {
    tv: Object,
    season: Object,
    action: Function,
    active: Boolean
  },
  computed: {
    imgObj: function () {
      return {
        src: 'https://assets.le-navet.com/static/300' + this.season.poster_path,
        error: 'https://europe-west1-turnip-820de.cloudfunctions.net/importImage?image=' + this.season.poster_path
      };
    }
  }
}
</script>

<style scoped>

</style>

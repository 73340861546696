<template>
  <layout>
    <v-progress-linear
      v-if="updatedElements && totalElements && (updatedElements < totalElements)"
      :value="(updatedElements*100)/totalElements"
    ></v-progress-linear>
    <v-container fluid v-if="tv">
      <v-speed-dial
        v-model="fab"
        fixed
        bottom
        right
        direction="top"
      >
        <template v-slot:activator>
          <v-btn
            v-model="fab"
            color="blue darken-2"
            dark
            fab
          >
            <v-icon v-if="fab">mdi-close</v-icon>
            <v-icon v-else>mdi-cogs</v-icon>
          </v-btn>
        </template>
        <v-btn
          fab
          dark
          small
          color="red"
        >
          <v-icon>mdi-delete</v-icon>
        </v-btn>
        <v-btn
          fab
          dark
          small
          color="indigo"
          @click="updateRatings"
        >
          <v-icon>mdi-star-half</v-icon>
        </v-btn>
        <v-btn
          fab
          dark
          small
          color="green"
          @click="update"
          :loading="updating"
        >
          <v-icon>mdi-update</v-icon>
        </v-btn>
      </v-speed-dial>
      <v-row>
        <v-col cols="12">
          <v-row>
            <v-col md="3" lg="2" v-if="$vuetify.breakpoint.mdAndUp && tv.poster_path">
              <img style="width: 100%;"
                   v-lazy="imgObj(tv.poster_path)">
            </v-col>
            <v-col cols="12" md="9" lg="10">
              <v-row>
                <v-col cols="12">
                  <h1 style="font-weight: bolder;">
                    <p class="my-0 py-0" v-text="tv.name"></p>
                  </h1>
                </v-col>
              </v-row>
              <v-row>
                <v-col class="pt-0" cols="12">
                  <h5>
                    <b v-if="tv.first_air_date" v-text="first_air_date.format('Y')"></b>
                  </h5>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12">
                  <v-chip v-if="tv.vote_average">
                    <v-avatar tile left>
                      <v-img src="/img/tmdb.svg" width="25" height="25"></v-img>
                    </v-avatar>
                    &nbsp;{{ tv.vote_average }}/10
                  </v-chip>
                  <v-chip v-for="(rating, index) in ratings" :key="index">
                    <v-avatar tile left>
                      <v-img v-if="rating.Source == 'Internet Movie Database'" src="/img/imdb.png" width="20" height="20"></v-img>
                      <v-img v-if="rating.Source == 'Metacritic'" src="/img/metacritic.svg" width="25" height="25"></v-img>
                      <v-img v-if="rating.Source == 'Rotten Tomatoes' && rating.Value.slice(0, -1) > 60" src="/img/rotten-fresh.png" width="25" height="25"></v-img>
                      <v-img v-if="rating.Source == 'Rotten Tomatoes' && rating.Value.slice(0, -1) <= 60" src="/img/rotten-rotten.png" width="25" height="25"></v-img>
                    </v-avatar>
                    {{ rating.Value }}
                  </v-chip>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12">
                  <p v-text="tv.overview"></p>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12">
                  <v-btn tile :to="'/playtv/'+tv.id+'/'+continueSeason+'/'+continueEpisode" color="error" v-if="continueSeason && continueEpisode">
                    <v-icon left>play_arrow</v-icon>
                    Continue Season {{continueSeason}} Episode {{continueEpisode}}
                  </v-btn>
                  <v-btn tile :to="'/playtv/'+tv.id+'/'+firstSeason+'/1'" color="error" v-else-if="!continueSeason && firstSeason">
                    <v-icon left>play_arrow</v-icon>
                    Play Season {{firstSeason}} Episode 1
                  </v-btn>
                  <!--<button v-if="!inList" class="btn btn-secondary" @click="togglelist()">
                    <i class="fas fa-plus"></i> My List
                  </button>
                  <button v-if="inList" class="btn btn-secondary" @click="togglelist()">
                    <i class="fas fa-check"></i> My List
                  </button>
                  <button v-if="!rating || rating.rating === 2" type="button"
                          class="btn btn-outline-light" @click="vote(2)">
                    <i class="fas fa-thumbs-up"></i>
                  </button>
                  <button v-if="!rating || rating.rating === 1" type="button"
                          class="btn btn-outline-light" @click="vote(1)">
                    <i class="fas fa-thumbs-down"></i>
                  </button>-->
                </v-col>
              </v-row>
              <v-divider></v-divider>
              <v-row v-if="director">
                <v-col cols="12">
                  Director:
                  <router-link :to="'/people/'+director.id">
                    <span v-text="director.name"></span>
                  </router-link>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" v-if="cast">
                  Starring:
                  <span v-for="(character, index) in cast.slice(0, 8)" :key="index">
                    <router-link :to="'/people/'+character.id">{{character.name}}</router-link>
                                    <span v-if="index+1 < cast.slice(0, 8).length">, </span>
                                </span>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12">
                  Genres:
                  <span v-for="(genre, index) in tv.genres" :key="index">
                                    <span v-text="genre.name"></span><span
                    v-if="index+1 < tv.genres.length">, </span>
                                </span>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-row v-if="collection">
                <v-col cols="12">
                  <div class="row">
                    <div class="col">
                      Collection: <span v-text="collection.name"></span>
                      <ul>
                        <li v-for="item in collection.movies" :key="item.id">
                          <router-link :to="'/movies/'+item.id" v-text="item.title"></router-link>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div class="row">
                  </div>
                </v-col>
              </v-row>
              <v-divider/>
              <v-row>
                <v-col>
                  <v-list dense>
                    <v-subheader>REPORTS</v-subheader>
                    <v-list-item-group color="primary">
                      <v-list-item disabled>
                        <v-list-item-icon>
                          <v-icon>access_time</v-icon>
                        </v-list-item-icon>
                        <v-list-item-content>
                          <v-list-item-title>{{seasons.length}}</v-list-item-title>
                        </v-list-item-content>
                      </v-list-item>
                      <v-list-item disabled>
                        <v-list-item-icon>
                          <v-icon>calendar_today</v-icon>
                        </v-list-item-icon>
                        <v-list-item-content>
                          <v-list-item-title>{{first_air_date.format('L')}}</v-list-item-title>
                        </v-list-item-content>
                      </v-list-item>
                      <v-list-item disabled v-if="tv && tv.created_by">
                        <v-list-item-icon>
                          <v-icon>attach_money</v-icon>
                        </v-list-item-icon>
                        <v-list-item-content>
                          <v-list-item-title>{{tv.created_by[0].name}}</v-list-item-title>
                        </v-list-item-content>
                      </v-list-item>
                    </v-list-item-group>
                  </v-list>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
          <v-row v-if="video" justify="center">
            <v-col cols="12" lg="8" md="10">
              <iframe class="embed-responsive-item"
                      :src="'https://www.youtube.com/embed/' + video.key + '?rel=0'"
                      style="width: 100%; height:30vw;"
                      allowfullscreen></iframe>
            </v-col>
          </v-row>
          <v-row justify="center">
            <v-col cols="12">
              <v-slide-group
                :show-arrows="$vuetify.breakpoint.lgAndUp"
              >
                <v-slide-item
                  v-for="(season, n) in seasons"
                  :key="n"
                  v-slot:default="{ active, toggle }"
                  style="width: 150px; height: auto;"
                >
                  <Season class="mx-1" :active="active" :action="toggle" :tv="tv" :season="season"></Season>
                </v-slide-item>
              </v-slide-group>
            </v-col>
          </v-row>
          <!--
          <v-divider></v-divider>
          <div class="row" v-if="posters">
            <div class="col">
              <div class="row">
                <div class="col">
                  <h2>Posters</h2>
                </div>
              </div>
              <v-slide-group
                v-model="posterModel"
                show-arrows
              >
                <v-slide-item
                  v-for="(poster, n) in posters"
                  :key="n"
                  v-slot:default="{ active, toggle }"
                >
                  <v-card
                    :color="active ? 'primary' : 'grey lighten-1'"
                    width="200"
                    max-width="400"
                    @click="toggle"
                  >
                    <v-row
                      class="ma-0 pa-0"
                      align="center"
                      justify="center"
                      style="width: 100%;"
                    >
                      <v-col cols="12"
                             class="ma-0 pa-0"
                             align="center"
                             justify="center">
                      <v-scale-transition>
                        <v-icon
                          style="position: absolute;"
                          v-if="active"
                          color="white"
                          size="48"
                          v-text="'mdi-close-circle-outline'"
                        ></v-icon>
                      </v-scale-transition>
                      <img v-lazy="imgObj(poster.file_path)"
                           height="100%"
                           width="100%"
                      />
                      </v-col>
                    </v-row>
                  </v-card>
                </v-slide-item>
              </v-slide-group>
            </div>
          </div>
          <v-divider></v-divider>
          <div class="row" v-if="backdrops">
            <div class="col">
              <div class="row">
                <div class="col">
                  <h2>Backdrops</h2>
                </div>
              </div>
              <v-slide-group
                v-model="backdropModel"
                show-arrows
              >
                <v-slide-item
                  v-for="(backdrop, n) in backdrops"
                  :key="n"
                  v-slot:default="{ active, toggle }"
                >
                  <v-card
                    :color="active ? 'primary' : 'grey lighten-1'"
                    width="300"
                    @click="toggle"
                  >
                    <v-row
                      class="ma-0 pa-0"
                      align="center"
                      justify="center"
                      style="width: 100%;"
                    >
                      <v-col cols="12"
                             class="ma-0 pa-0"
                             align="center"
                             justify="center">
                      <v-scale-transition>
                        <v-icon
                          style="position: absolute;"
                          v-if="active"
                          color="white"
                          size="48"
                          v-text="'mdi-close-circle-outline'"
                        ></v-icon>
                      </v-scale-transition>
                      <img v-lazy="imgObj(backdrop.file_path)"
                           height="100%"
                           width="100%"
                      />
                      </v-col>
                    </v-row>
                  </v-card>
                </v-slide-item>
              </v-slide-group>
            </div>
          </div>
          -->
        </v-col>
      </v-row>
    </v-container>
  </layout>
</template>

<script>
import Layout from '../Layout';
import Season from '../components/Season';
import moment from 'moment';
import numeral from 'numeral';
import utils from '../utils';
import firebase from 'firebase/app';
import 'firebase/firestore';
import { moviedb } from '../variables';
import axios from 'axios';

export default {
  metaInfo () {
    if (this.translation) {
      return {
        title: this.translation.title
      };
    }
    return {
      title: this.tv.name
    };
  },
  data () {
    return {
      fab: false,
      updating: false,
      totalElements: 0,
      updatedElements: 0,
      collection: null,
      tv: {},
      seasons: [],
      videos: [],
      crew: [],
      cast: [],
      ratings: [],
      posterModel: null,
      posters: [],
      backdropModel: null,
      backdrops: [],
      continueSeason: null,
      continueEpisode: null,
      firstSeason: null
    };
  },
  components: {
    Layout,
    Season
  },
  computed: {
    video: function () {
      return this.videos[0] || null;
    },
    first_air_date: function () {
      return moment(this.tv.first_air_date);
    },
    budget: function () {
      return this.formatPrice(this.tv.budget);
    },
    revenue: function () {
      return this.formatPrice(this.tv.revenue);
    },
    director: function () {
      for (let i = 0; i < this.crew.length; i++) {
        if (this.crew[i].job === 'Director') {
          return this.crew[i];
        }
      }
      return null;
    }
  },
  methods: {
    imgObj (path) {
      return {
        src: 'https://assets.le-navet.com/static/300' + path,
        error: 'https://europe-west1-turnip-820de.cloudfunctions.net/importImage?image=' + path
      };
    },
    formatTime (time) {
      return utils.timeToString(time, false);
    },
    formatPrice (value) {
      return numeral(value).format('($ 0.00 a)');
    },
    update () {
      this.updating = true;
      let firestore = firebase.firestore();
      let tvRef = firestore.collection('tv');
      let tvId = this.$route.params.id;

      this.totalElements = 0;
      this.updatedElements = 0;

      moviedb.tvInfo({ id: tvId }).then(res => {
        tvRef.doc(res.id + '').set(res);
        /*
        moviedb.tvVideos({id: tvId}).then(videos => {
          this.totalElements += videos.results.length;
          for (let videoIndex = 0; videoIndex < videos.results.length; videoIndex++) {
            let video = videos.results[videoIndex];
            // tvRef.doc(res.id + '').collection('videos').doc(video.id + '').set(video);
          }
        });
        */
        moviedb.tvCredits({ id: tvId }).then(tvCredits => {
          this.totalElements += tvCredits.cast.length;
          this.totalElements += tvCredits.crew.length;
          for (let j = 0; j < tvCredits.cast.length; j++) {
            tvRef.doc(res.id + '').collection('cast').doc(tvCredits.cast[j].credit_id).set(tvCredits.cast[j]).finally(() => {
              this.updatedElements++;
            });
          }
          for (let j = 0; j < tvCredits.crew.length; j++) {
            tvRef.doc(res.id + '').collection('crew').doc(tvCredits.crew[j].credit_id).set(tvCredits.crew[j]).finally(() => {
              this.updatedElements++;
            });
          }
        });
        moviedb.tvExternalIds({ id: tvId }).then(tvExternalIds => {
          this.totalElements++;
          tvRef.doc(res.id + '').update({ external_ids: tvExternalIds }).finally(() => {
            this.updatedElements++;
          })
        });
        moviedb.tvImages({ id: tvId }).then(tvImages => {
          tvRef.doc(res.id + '').collection('posters').get().then(sub => {
            if (sub.docs.length === 0) {
              this.totalElements += tvImages.posters.length;
              for (let j = 0; j < tvImages.posters.length; j++) {
                tvRef.doc(res.id + '').collection('posters').doc().set(tvImages.posters[j]).finally(() => {
                  this.updatedElements++;
                });
              }
            }
          });
          tvRef.doc(res.id + '').collection('backdrops').get().then(sub => {
            if (sub.docs.length === 0) {
              this.totalElements += tvImages.backdrops.length;
              for (let j = 0; j < tvImages.backdrops.length; j++) {
                tvRef.doc(res.id + '').collection('backdrops').doc().set(tvImages.backdrops[j]).finally(() => {
                  this.updatedElements++;
                });
              }
            }
          });
        });
        this.totalElements += res.seasons.length;
        for (let index = 0; index < res.seasons.length; index++) {
          let season = res.seasons[index];
          moviedb.tvSeasonInfo({ id: res.id, season_number: season.season_number }).then(seasonInfo => {
            this.totalElements += seasonInfo.episodes.length;
            tvRef.doc(res.id + '').collection('seasons').doc(season.id + '').set(seasonInfo).finally(() => {
              this.updatedElements++;
            });
            for (let indexx = 0; indexx < seasonInfo.episodes.length; indexx++) {
              let episode = seasonInfo.episodes[indexx];
              moviedb.tvEpisodeInfo({ id: res.id, season_number: season.season_number, episode_number: episode.episode_number }).then(episodeInfo => {
                tvRef.doc(res.id + '').collection('seasons').doc(season.id + '').collection('episodes').doc(episodeInfo.id + '').set(episodeInfo).finally(() => {
                  this.updatedElements++;
                });
              }).catch(console.error);
            }
          }).catch(console.error);
        }
        this.updating = false;
      }).catch(console.error);
    },
    updateRatings () {
      axios.get('https://www.omdbapi.com/?i=' + this.tv.external_ids.imdb_id + '&apikey=e31fcfc4')
        .then(response => {
          let firestore = firebase.firestore();
          let tvRef = firestore.collection('tv');
          for (let i = 0; i < response.data.Ratings.length; i++) {
            tvRef.doc(this.tv.id + '').collection('ratings').doc(i + '').set(response.data.Ratings[i]);
          }
        })
    }
  },
  created: function () {
    // let tvId = parseInt(this.$route.params.id);

    // this.$store.dispatch('gettv', { id: tvId });

    let tvId = this.$route.params.id;
    let firestore = firebase.firestore();
    let tvRef = firestore.collection('tv');
    tvRef.doc(tvId).get().then(snapshot => {
      this.tv = snapshot.data();
    });
    tvRef.doc(tvId).collection('videos').where('type', '==', 'Trailer').get().then(snapshot => {
      this.videos = [];
      snapshot.forEach(doc => {
        this.videos.push(doc.data());
      });
    });
    tvRef.doc(tvId).collection('seasons').where('season_number', '>', 0).orderBy('season_number', 'asc').get().then(snapshot => {
      this.seasons = [];
      snapshot.forEach(doc => {
        let season = doc.data();
        this.seasons.push(season);
        if (this.firstSeason === null || this.firstSeason > season.season_number) {
          this.firstSeason = season.season_number;
        }
      });
    });
    tvRef.doc(tvId).collection('posters').get().then(querySnapshot => {
      this.posters = [];
      querySnapshot.forEach((doc) => {
        this.posters.push(doc.data());
      });
    });
    tvRef.doc(tvId).collection('backdrops').get().then(querySnapshot => {
      this.backdrops = [];
      querySnapshot.forEach((doc) => {
        this.backdrops.push(doc.data());
      });
    });
    tvRef.doc(tvId).collection('cast').get().then(querySnapshot => {
      this.cast = [];
      querySnapshot.forEach((doc) => {
        this.cast.push(doc.data());
      });
    });
    tvRef.doc(tvId).collection('crew').get().then(querySnapshot => {
      this.crew = [];
      querySnapshot.forEach((doc) => {
        this.crew.push(doc.data());
      });
    });
    tvRef.doc(tvId).collection('ratings').get().then(snapshot => {
      this.ratings = [];
      snapshot.forEach(doc => {
        this.ratings.push(doc.data());
      });
    });

    firestore.collection('users').doc(firebase.auth().currentUser.uid).collection('tv_watches').doc(tvId).collection('seasons').orderBy('modified', 'desc').limit(1).get().then(query => {
      query.forEach(season => {
        this.continueSeason = season.data().season_number;
        season.ref.collection('episodes').orderBy('modified', 'desc').limit(1).get().then(episodes => {
          episodes.forEach(episode => {
            if (!episode.data().duration || episode.data().time || episode.data().duration - episode.data().time > 180) { // consider 3min from the end as watched
              this.continueEpisode = episode.id;
            } else {
              tvRef.doc(tvId).collection('seasons').where('season_number', '==', this.continueSeason).get().then(results => {
                results.forEach(result => {
                  result.ref.collection('episodes').where('episode_number', '==', episode.data().episode_number + 1).get().then(epResults => {
                    if (epResults.size === 1) { // exists
                      this.continueEpisode = episode.data().episode_number + 1;
                    } else {
                      this.continueEpisode = null;
                    }
                  });
                })
              });
            }
          });
        });
      });
    });
  }
};
</script>
